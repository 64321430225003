@font-face {
  font-family: 'FontText';
  font-weight: normal;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Regular.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Regular.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: normal;
  font-style: italic;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-RegularItalic.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-RegularItalic.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: bold;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Bold.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Bold.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: bold;
  font-style: italic;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-BoldItalic.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-BoldItalic.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: bolder;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Black.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Black.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: bolder;
  font-style: italic;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-BlackItalic.woff')
      format('woff'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-BlackItalic.woff2')
      format('woff2');
}

@font-face {
  font-family: 'FontText';
  font-weight: lighter;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Light.woff2')
      format('woff2'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'FontText';
  font-weight: lighter;
  font-style: italic;
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-LightItalic.woff2')
      format('woff2'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Text/MGPText-LightItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'FontDisplay';
  src: url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Display/Variable/MGPDisplay-VF.woff2')
      format('woff2 supports variations'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Display/Variable/MGPDisplay-VF.woff2')
      format('woff2-variations'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Display/Variable/MGPDisplay-VF.woff')
      format('woff supports variations'),
    url('https://dornacorporatestorage.blob.core.windows.net/public-assets/fonts/Display/Variable/MGPDisplay-VF.woff')
      format('woff-variations');
}
