.users-list {
  margin-right: 20px;
  width: 450px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;  
}

.filter-wrapper {
  display: flex;
  padding: 3px;
  border: 1px solid rgba(0,0,0,.08);
}

.users-list > .users-list-items > .k-hbox:hover {
  opacity: 0.6;
}

.users-list > .users-list-items {
  overflow-y: auto;
}

.users-list > .users-list-items > .k-hbox {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 4px;
}

.list-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 1em;
  margin: 0;
}

.users-footer {
  font-size: 14px;
  padding: 5px;
  flex: 0;
}

.k-listbox-content > div {
  cursor: pointer;
}

.k-listbox-content > div:hover {
  opacity: 0.6;
}

.selected {
  opacity: 1;
  background-color: lightgray;
}

.user-role {
  margin-left: auto;
}

.list-item-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.list-item {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #dee2e6 !important;
}

.user-avatar {
  margin-right: 10px;
}

.user-avatar span {
  text-transform: uppercase;
}

.listbox-content {
  flex: 1;
  overflow-y: auto;  
  width: 100% !important;
  height: 100% !important;
}
