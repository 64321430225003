.layout-title-wrapper {
  width: 250px;
  align-self: center;
  margin-bottom: 20px;
}

.message-wrapper {
  border-left: 4px solid #70bbfd;
  color: #646777;
  margin-bottom: 10px;
  padding-left: 10px;
}

.message-wrapper h3 {
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.message-wrapper h4 {
  margin-bottom: 0;
}
