.roles-list {
  margin-right: 40px;
  width: 450px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;  
}

.filter-wrapper {
  display: flex;
  padding: 3px;
  border: 1px solid rgba(0,0,0,.08);
}

.roles-list > .roles-list-items > .k-hbox:hover {
  opacity: 0.6;
}

.roles-list > .roles-list-items {
  overflow-y: auto;
}

.roles-list > .roles-list-items > .k-hbox {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 4px;
}

.list-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 1em;
  margin: 0;
}

.roles-footer {
  font-size: 14px;
  padding: 5px;
  flex: 0;
}

.k-listbox-content > div {
  cursor: pointer;
}

.k-listbox-content > div:hover {
  opacity: 0.6;
}

.selected {
  opacity: 1;
  background-color: lightgray;
}

.list-item-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.list-item {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #dee2e6 !important;
}

.role-avatar {
  margin-right: 10px;
}

.user-avatar span {
  text-transform: uppercase;
}

.listbox-content {
  flex-shrink: 1;
  flex-basis: 0;
  height: calc(100% - 13px) !important;
  width: 100% !important;
}
