.fields-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.field-panel {
  flex: 1;
  padding-right: 15px;
  padding-top: 10px;
  max-width: 200px;
}

.field-panel-tree {
  flex: 1;
  padding-right: 15px;
  padding-top: 10px;
  max-width: 500px;
  height: 100%;
  overflow-y: scroll;
}

.auth-manager-container {
  display: flex;
  min-width: 260px;
  height: 100%;
  width: 100%; 
}
