.notification-group-wrapper {
  right: 0;
  top: 0;
  margin-right: 5px;
  z-index: 30000;
}

.k-notification-wrap {
  padding: 5px;
  font-size: 14px;
}

.k-notification-wrap > .k-icon {
  margin-top: 2px;
}
