.actions-toolbar {
  display: flex;
  height: 30px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 5px;
}

.action-toolbar-button {
  margin-left: 5px;
}
