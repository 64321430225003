.user-icon {
  font-size: 24px;
}

.k-menu-link {
  font-size: 18px;
}

.profile-wrapper {
  transition: 0.3;
}

.profile-wrapper .k-item {
  color: #656565 !important;
}
