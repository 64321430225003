.user-detail {
  flex: 1;
}

.user-change-password{
  flex: 1;
}

.custom-legend {
  margin-bottom: 0 !important;
  border-width: 0 0 2px;
  border-style: solid;
  font-size: 18px;
  text-transform: uppercase;
  border-color: rgba(0,0,0,.08);
}

.fields-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.field-panel {
  flex: 1;
  padding-right: 15px;
  padding-top: 10px;
}

