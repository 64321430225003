$font-family: 'FontText';
$font-headers-family: 'FontDisplay';

$primary: #70bbfd;
$secondary: #999999;
$font-size: 14px;

$enable-typography: 'true';

$grid-padding-x: 8px !default;
$grid-padding-y: 4px !default;

$tabstrip-item-text: $secondary;
$tabstrip-item-selected-text: #424242;

$input-focused-border: $primary;

$kendo-menu-popup-border-width: 0px;

$drawer-item-font-size: 14px;
$drawer-item-line-height: 1;

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-headers-family !important;
}

.k-menu-popup {
  border-width: 0px;
  border-style: none;
}

.font-ligth {
  font-weight: lighter;
}

.topbar-title {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 'bold';
  margin-right: 15px;
}

.topbar-nav-item .topbar-nav-text {
  margin-top: 0;
  font-size: 14px;
}

.container__wrap.production-layout {
  padding-right: 20px;
  padding-left: 20px !important;
  padding-top: 65px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.margin-5 {
  margin: 0 5px;
}

#root {
  height: 100%;
  display: flex;
}

.filter-button {
  align-self: flex-end;
}

.filter {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.k-drawer-content {
  align-self: stretch;
}

input.k-input:disabled{
  color: rgb(170, 170, 170);
}

.k-notification-group .k-notification{
  display: flex;
  min-height: 50px;
  align-content: center;
  flex-flow: wrap;
}

@import '~@progress/kendo-theme-default/dist/all.scss';
@import './load.scss';
