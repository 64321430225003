.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10003;
  opacity: 0.7;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

.k-animation-container-shown {
  z-index: 1000;
}

.k-treeview-item .k-treeview-leaf.k-selected{
  color: black;
  background-color: #99999961;
}

.k-treeview.k-treeview-md{
  display: flex;
  scroll-behavior: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
