.users-wrapper {
  flex: 1;
  display: flex;
  height: 100%;
}

.user-card-container {
  flex: 1;
  display: flex;
  width: 100%;
}

.user-card-item {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
  margin: 10px;
  flex-grow: 1;
  width: 50%;
  max-width: 50%;
}
